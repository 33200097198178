import { PageMessage } from '@netfront/ui-library';
import { NextPage } from 'next';

const Error404Page: NextPage = () => {
  return (
    <PageMessage
      contactUrl="/contact"
      dashboardUrl={`/partner/dashboard`}
      headerText="page not found!"
      message="Looks like the page you are looking for isn't here. Don't worry, you can jump back to a safe place below"
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default Error404Page;
